<template>
    <div class="about-area pt-120 pb-90">
        <div class="container">
            <div class="row">
                <div class="col-xl-6 col-lg-6">
                    <div class="ab-wrapper pos-rel">
                        <div class="ab-since p-3">
                            <h4><img src="../../assets/img/about/logo.png" alt="" class="w-100"></h4>
                        </div>
                        <div class="row gx-2 mb-30">
                            <div class="col-6">
                                <div class="ab-img img-filter">
                                    <img src="../../assets/img/about/girl-ar.jpg" alt="">
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="ab-img img-filter">
                                    <img src="../../assets/img/about/xr-world.jpg" alt="">
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="ab-img img-filter">
                                    <img src="../../assets/img/about/robot-cellphone.jpg" alt="">
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="ab-img img-filter">
                                    <img src="../../assets/img/about/smart-city.jpg" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6">
                    <div class="ab-box pl-50 mb-30">
                        <div class="sec-wrapper">
                            <h5>About Us</h5>
                            <p>Established in 2023, our web-based AR app seamlessly augments the world around you. We are explorers, makers, and visionaries - united by a relentless drive to push boundaries.</p>
                            <p>Join us as we peel back the layers of possibility, blending the digital and physical into one phenomenal experience.</p>
                            <p>The future is already here, and it's never looked better.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AboutUs'
}
</script>