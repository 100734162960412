<template>
    <Header/>
    <Breadcrumb title="Faq" subtitle="Faq" />
    <FaqArea/>
    <Team/>
    <Testimonial/>
    <Brand/>
    <Footer/>
</template>

<script>
import Header from '../../components/Home/Header.vue';
import Breadcrumb from '../../components/common/Breadcrumb.vue';
import FaqArea from '../../components/Faq/FaqArea.vue';
import Team from '../../components/Home/Team.vue';
import Testimonial from '../../components/common/Testimonials.vue';
import Brand from '../../components/common/BrandArea.vue';
import Footer from '../../components/Home/Footer.vue';

export default {
   name:'FaqPage',
   components:{
       Header,
       Breadcrumb,
       FaqArea,
       Team,
       Testimonial,
       Brand,
       Footer,
   }
}
</script>