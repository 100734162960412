<template>
     <div class="contact-map">
        <div id="contact-map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1121.0323868393828!2d106.83572053802547!3d-6.242025034444527!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f3c1525dfbd3%3A0xa14cc394a101766c!2sSME%20Tower!5e0!3m2!1sen!2sid!4v1691694670242!5m2!1sen!2sid" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
    </div>
</template>

<script>
export default {
    name:'CotactMap'
}
</script>