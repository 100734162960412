<template>
  <section
    class="review-area review-area-padding pos-rel pl-50 pr-50 grey-bg pt-120 pb-90">
   
    <div class="container">
      <div class="row mb-40 z-index">
        <div class="col-12">
          <div class="sec-wrapper text-center">
            <h5>Testimonials</h5>
            <h2 class="section-title">Feedback from our clients.</h2>
          </div>
        </div>
      </div>
      <div class="test-active swiper-container">
        <swiper
          ref="mySwiper"
          class="swiper-wrapper pb-70"
          :space-between="25"
          :slides-per-view="3"
          :modules="modules"
          :pagination="{ clickable: true }"
          :loop="true"
          :breakpoints="breakpoints"
        >
          <swiper-slide
            v-for="testimonial in testimonialData"
            :key="testimonial.id">
            <div class="testi-item swiper-slide">
              <div class="tptestinimail">
                <div class="tptestinimail__text">
                  <p>
                    Lorem ipsum dolor sit amet, coning ctetur adipisicing elit,
                    sed do it on eiusmod tempor incididunt me ut labore et
                    dolore.
                  </p>
                </div>
                <div class="tptestinimail__author d-sm-flex align-items-center">
                  <div class=" tptestinimail__author--img tptestinimail__author--img-2 " >
                    <img :src="getImgUrl(testimonial.img)" alt="" />
                  </div>
                  <div class="tptestinimail__author--bio">
                    <h4>{{testimonial.name}}</h4>
                    <h6>{{testimonial.title}}</h6>
                  </div>
                </div>
                <div class="testimonial-quote text-end">
                  <i class="fa fa-quote-right"></i>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>

        <div class="swiper-pagination"></div>
      </div>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination } from "swiper";

export default {
  name: "HomeTwoTestimonials",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      testimonialData: [
        {
          id: "1",
          img: "client-2.png",
          name: "Mr. Habib Hemel",
          title: "HR Admin",
        },
        {
          id: "2",
          img: "client-3.png",
          name: "Mr. Salim Dawn",
          title: "Developer",
        },
        {
          id: "3",
          img: "client-4.png",
          name: "Mr. Suhan Dol",
          title: "Designer",
        },
        {
          id: "4",
          img: "client-5.png",
          name: "Mr. Williamson",
          title: "Pure Founder",
        },
        {
          id: "5",
          img: "client-1.png",
          name: "Mr. Jonota Gilai",
          title: "BDevs Officer",
        },
        {
          id: "6",
          img: "client-6.png",
          name: "Mr. Shewkh Kamal",
          title: "ThemePure",
        },
      ],
      breakpoints: {
				0: {slidesPerView: 1,},
				576: {slidesPerView: 1,},
				768: {slidesPerView: 2,},
				992: {	slidesPerView: 3,},
				1400: {slidesPerView: 3,},
		},
    };
  },
  methods: {
    getImgUrl(pic) {
      return require("../../assets/img/testimonial/" + pic);
    },
  },

  setup() {
    return {
      modules: [Pagination],
    };
  },
};
</script>