<template>
    <div :class="brandBorder === 'brand-border-2' ? `brand-area bg-grey pt-100 pb-100 ${brandBorder}` :
        `brand-area bg-grey pt-100 pb-100 ${border && border}`">
        <div class="container">
            <h2 class="text-center mb-20 section-title">Our <span>Clients</span></h2>
            <p class="mb-50 text-center">Empowering our clients with cutting-edge Augmented Reality, App Development, and Computer Vision solutions. Transform ideas into reality, automate processes, and gain unparalleled insights. Partner with us for innovative, seamless, and impactful technology experiences.</p>
            <div class="brand-active swiper-container">
                <swiper ref="mySwiper" class="swiper-wrapper align-items-center" :space-between="25" :slides-per-view="3" :modules="modules" :autoplay="{ delay: 3000 }" :loop="true" :breakpoints="breakpoints">
                    <swiper-slide v-for="brand in brandData" :key="brand.id">
                        <div class="brand-wrapper swiper-slide">
                            <a href="#">
                                <img :src="getImgUrl(brand.img)" class="img-fluid" alt="img" />
                            </a>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { EffectFade, Autoplay } from "swiper";
export default {
    name: "BrandArea",
    props: {
        brandBorder: String,
        border: String,
    },
    components: {
        Swiper,
        SwiperSlide,
    },
    data() {
        return {
            brandData: [
                {
                    id: "1",
                    img: "brand-SEI.png",
                },
                {
                    id: "2",
                    img: "brand-pamjaya.jpg",
                },
                {
                    id: "3",
                    img: "brand-tmmin.jpg",
                },
                {
                    id: "4",
                    img: "brand-unilever.png",
                },
                {
                    id: "5",
                    img: "brand-sim.png",
                },
                {
                    id: "6",
                    img: "brand-adm.png",
                },
                {
                    id: "7",
                    img: "brand-sml.png",
                },
                {
                    id: "8",
                    img: "brand-osa.png",
                },

            ],
            breakpoints: {
                550: { slidesPerView: 3 },
                768: { slidesPerView: 4 },
                1200: { slidesPerView: 5 },
            }

        };
    },
    methods: {
        getImgUrl(pic) {
            return require("../../assets/img/brand/" + pic);
        },
    },

    setup() {
        return {
            modules: [EffectFade, Autoplay],
        };
    },
};
</script>