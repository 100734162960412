<template>
     <!-- <section class="contact-area pt-120 pb-80 fix" :style="{ backgroundImage: 'url(' + require('../../assets/img/contact/girl-bnw.jpg') + ')', backgroundRepeat: 'no-repeat', backgroundSize:'cover', backgroundPosition: '0 20%' }" data-background="assets/img/contact/girl-bnw.jpg"> -->
        <section class="contact-area pt-120 pb-80 fix">
         <div class="container" >
             <div class="row">
                 <div class="col-xxl-5 col-xl-6 col-lg-6">
                     <div class="section-title-wrapper mb-15">
                         <h5 class="section-subtitle mb-20">contact with us</h5>
                         <h2 class="section-title">Speak with our team</h2>
                     </div>
                     <div class="contact-info mr-50 mr-xs-0  mr-md-0">
                         <div class="single-contact-info d-flex align-items-center">
                             <div class="contact-info-icon">
                                 <a href="#"><i class="fab fa-whatsapp"></i></a>
                             </div>
                             <div class="contact-info-text mt-10">
                                 <span> Whatsapp</span>
                                 <h5><a href="https://wa.me/6287729700999"> (+62) 877 29700 999</a></h5>
                             </div>
                         </div>
                         <div class="single-contact-info d-flex align-items-center">
                             <div class="contact-info-icon">
                                 <a href="#"><i class="fal fa-envelope"></i></a>
                             </div>
                             <div class="contact-info-text mt-10">
                                 <span>send email</span>
                                 <h5><a href="mailto:support@nobaar.id">support@nobaar.id</a> </h5>
                             </div>
                         </div>
                         <div class="single-contact-info d-flex align-items-center">
                             <div class="contact-info-icon">
                                 <a href="#"><i class="fal fa-map-marker-alt"></i></a>
                             </div>
                             <div class="contact-info-text mt-10">
                                 <span>visit office</span>
                                 <h5><a target="_blank" href="https://goo.gl/maps/FYrZqbtdpTPQV2279">Smesco SME Tower, Jalan Gatot Subroto Kaveling 94, DKI Jakarta - Indonesia</a></h5>
                             </div>
                         </div>
                     </div>
                 </div>
                 <div class="col-xxl-7 col-xl-6 col-lg-6 d-none">
                     <div class="contact-form">
                         <form action="mail.php" id="contact-form" method="POST">
                             <div class="row">
                                 <div class="col-xxl-6 col-xl-6 col-lg-6 mb-20">
                                     <input name="name" type="text" placeholder="Your Name">
                                 </div>
                                 <div class="col-xxl-6 col-xl-6 col-lg-6 mb-20">
                                     <input name="email" type="email" placeholder="Email Adress">
                                 </div>
                                 <div class="col-xxl-6 col-xl-6 col-lg-6 mb-20">
                                     <input name="phone" type="text" placeholder="Phone">
                                 </div>
                                 <div class="col-xxl-6 col-xl-6 col-lg-6 mb-20">
                                     <input name="subject" type="text" placeholder="Subject">
                                 </div>
                                 <div class="col-xxl-12 col-xl-12 col-lg-12 mb-20">
                                     <textarea placeholder="Write Massage" name="massage"></textarea>
                                 </div>
                                 <div class="col-xxl-12 col-xl-12 mb-20">
                                     <button type="submit" class="tp-btn">Send a message</button>
                                 </div>
                             </div>
                         </form>
                         <p class="ajax-response"></p>
                     </div>
                 </div>
             </div>
         </div>
    </section>
</template>

<script>
export default {
    name:'ContactArea',
}
</script>