<template>
    <div :class="brandBorder === 'brand-border-2' ? `brand-area bg-grey pt-100 pb-100 ${brandBorder}` :
        `brand-area bg-grey pt-100 pb-100 ${border && border}`">
        <div class="container">
            <div class="brand-active swiper-container">
                <swiper ref="mySwiper" class="swiper-wrapper align-items-center" :space-between="25" :slides-per-view="1" :modules="modules" :autoplay="{ delay: 5000 }" :loop="true" :breakpoints="breakpoints">
                    <swiper-slide v-for="service in brandData" :key="service.id">
                        <div class="brand-wrapper swiper-slide">
                            <a href="#">
                                <img :src="getImgUrl(service.img)" class="img-fluid" alt="img" />
                            </a>
                            <h2>{{ service.title }}</h2>
                            <p>{{ service.subtitle }}</p>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { EffectFade, Autoplay } from "swiper";
export default {
    name: "BrandArea",
    props: {
        brandBorder: String,
        border: String,
    },
    components: {
        Swiper,
        SwiperSlide,
    },
    data() {
        return {
            brandData: [
                {
                    id: "1",
                    img: "360-view.gif",
                    title: "AUGMENTED REALITY",
                    subtitle: "Driven by a spirit of relentless innovation, we are architects crafting the future of integrated realities. Our passionate team of visionaries develops cutting-edge AR capabilities for your needs.",
                },
                {
                    id: "2",
                    img: "home-security.gif",
                    title: "SMART SYSTEM",
                    subtitle: "Revolutionize your operations with our Smart System solutions. Automate tasks, enhance efficiency, and gain real-time insights.",
                },
                {
                    id: "3",
                    img: "ai.gif",
                    title: "COMPUTER VISION",
                    subtitle: "Merging physical reality with digital intelligence. See more, achieve more with visionary Computer Vision solutions. Enhance accuracy, automate processes, and gain valuable insights.",
                },
                {
                    id: "4",
                    img: "responsive.gif",
                    title: "APP DEVELOPMENT",
                    subtitle: "Transform your ideas into powerful mobile applications. Our expert app development service ensures a seamless user experience with cutting-edge technology, customized solutions, and on-time delivery.",
                },

            ],
            breakpoints: {
                550: { slidesPerView: 1 },
                768: { slidesPerView: 2 },
                1200: { slidesPerView: 4 },
            }

        };
    },
    methods: {
        getImgUrl(pic) {
            return require("../../assets/img/service/" + pic);
        },
    },

    setup() {
        return {
            modules: [EffectFade, Autoplay],
        };
    },
};
</script>