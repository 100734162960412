<template>
    <Header/>
    <HeroSlider/>
    <!-- <Services/> -->
    <!-- <About/> -->
    <!-- <Counter/> -->
    <!-- <Team/> -->
    <!-- <Faq/> -->
    <!-- <Portfolio/> -->
    <!-- <Cta/> -->
    <!-- <Blog/> -->

    <Brand border="brand-border" />

    <Footer/>
</template>

<script>
import Header from '../../components/Home/Header.vue';
import HeroSlider from '../../components/Home/HeroSlider.vue';
// import Services from '../../components/Home/Services.vue';
// import About from '../../components/Home/About.vue';
// import Counter from '../../components/Home/Counter.vue';
// import Team from '../../components/Home/Team.vue';
// import Faq from '../../components/Home/Faq.vue';
// import Portfolio from '../../components/Home/Portfolio.vue';
// import Cta from '../../components/Home/Cta.vue';
// import Blog from '../../components/Home/Blog.vue';
import Brand from '../../components/common/BrandArea.vue';
import Footer from '../../components/Home/Footer.vue';

export default {
    name:'HomePage',
    components:{
        Header,
        HeroSlider,
        // Services,
        // About,
        // Counter,
        // Team,
        // Faq,
        // Portfolio,
        // Cta,
        // Blog,
        Brand,
        Footer,
    }
}
</script>