<template>
    <Header/>
    <Breadcrumb title="Services" subtitle="Services" />
    <!-- <HeroSlider/> -->
    <!-- <Services/> -->
    <ServicesArea/>
    <!-- <About/> -->
    <!-- <Counter/> -->
    <!-- <Team/> -->
    <!-- <Faq/> -->
    <!-- <Portfolio/> -->
    <!-- <Cta/> -->
    <!-- <Blog/> -->
    <Footer/>
</template>

<script>
import Header from '../../components/Home/Header.vue';
import Breadcrumb from '../../components/common/Breadcrumb.vue';
// import HeroSlider from '../../components/Home/HeroSlider.vue';
// import Services from '../../components/Home/Services.vue';
// import About from '../../components/Home/About.vue';
// import Counter from '../../components/Home/Counter.vue';
// import Team from '../../components/Home/Team.vue';
// import Faq from '../../components/Home/Faq.vue';
// import Portfolio from '../../components/Home/Portfolio.vue';
// import Cta from '../../components/Home/Cta.vue';
// import Blog from '../../components/Home/Blog.vue';
import ServicesArea from '../../components/common/ServicesArea.vue';
import Footer from '../../components/Home/Footer.vue';

export default {
    name:'ServicesPage',
    components:{
        Header,
        Breadcrumb,
        // HeroSlider,
        // Services,
        // About,
        // Counter,
        // Team,
        // Faq,
        // Portfolio,
        // Cta,
        // Blog,
        ServicesArea,
        Footer,
    }
}
</script>